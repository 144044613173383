import { forwardRef, useEffect, useRef } from 'react';

const TextInput = forwardRef(function TextInput({ type = 'text', className = '', isFocused = false, ...props }, ref) {
    const inputRef = ref || useRef(null);

    useEffect(() => {
        if (isFocused && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isFocused]);

    return (
        <input
            {...props}
            type={type}
            value={props.value ?? ''}
            className={`border-gray-300 focus:border-uhl-light-blue focus:ring-uhl-light-blue rounded-md shadow-sm px-2 py-1.5 w-full mt-1 ${className}`}
            ref={inputRef}
        />
    );
});

export default TextInput;
